import React, { FC } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import BlogPost from "../components/BlogUiComponents/BlogPost/BlogPost";
import { UseWindowSize } from "../components/hooks/UseWindowSize";
import MobileBlogPost from "../components/BlogUiComponents/BlogPost/MobileBlogPost";
import { SEO } from "../components/seo";

import { ImageSeoUrlCheck } from "../components/Shared/ImageSeoUrlCheck/ImageSeoUrlCheck";
type Props = {
  data: any;
  location: any;
};

const NewsBlogPostTemplate: FC<Props> = ({ data, location }) => {
  const { width } = UseWindowSize();
  return (
    <Layout location="normalFooter">
      <SEO
        title={data.sanityBlog.title}
        description={data.sanityBlog.subtitle}
        image={ImageSeoUrlCheck(data.sanityBlog._rawMainImage.asset.url)}
        type="article"
        author={data.sanityBlog.author.name}
        publishedtime={data.sanityBlog.publishedAt}
      />
      {width > 770 ? (
        <BlogPost
          pathname={location.pathname}
          blogCategory={"news"}
          post={data} //sanity
          allData={data.allSanityBlog}
        />
      ) : (
        <MobileBlogPost
          blogCategory={"news"}
          allData={data.allSanityBlog}
          post={data} //sanity
        />
      )}
    </Layout>
  );
};

export default NewsBlogPostTemplate;

export const query = graphql`
  query ($slug: String!) {
    sanityBlog(id: { eq: $slug }) {
      id
      _rawBody(resolveReferences: { maxDepth: 20 })
      _rawAuthor(resolveReferences: { maxDepth: 20 })
      _rawAuthorTwo(resolveReferences: { maxDepth: 20 })
      _rawMainImage(resolveReferences: { maxDepth: 20 })
      body {
        children {
          _key
          _type
          text
        }
        style
        _type
      }
      author {
        image {
          asset {
            url
            title
          }
        }
        alt
        position
        name
        activeMember
      }
      authorTwo {
        image {
          asset {
            url
            title
          }
        }
        alt
        name
        position
        activeMember
      }
      publishedAt
      subtitle
      title
      _createdAt
      tags {
        title
      }
    }

    allSanityBlog(
      filter: { blogType: { elemMatch: { title: { eq: "news" } } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          subtitle
          title
          publishedAt
          blogType {
            title
          }
          body {
            children {
              text
            }
          }
          tags {
            title
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          alt
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
`;
